import { FC, ReactNode, useEffect } from 'react';
import { useAccount } from 'modules/account/accountSelector';
import { MODULE_STATUS } from 'constants/modules';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import routeConfig from 'app/config/routes';

type Props = {
  children: ReactNode;
};

const AccountOnboardingChecker: FC<Props> = ({ children }) => {
  const location = useLocation();
  const account = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    if (account.status === MODULE_STATUS.Succeeded) {
      if (!account.onboarded) {
        navigate(routes.ONBOARDING_SETUP_ACCOUNT);
      }

      if (
        location.pathname.includes(routeConfig.onboarding.path) &&
        account.onboarded
      ) {
        navigate('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.status, account.onboarded, location.pathname]);

  return <>{children}</>;
};

export default AccountOnboardingChecker;
